{
"type": "FeatureCollection",
"name": "main_level_labels",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::3857" } },
"features": [
{ "type": "Feature", "id": "sci_hub", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753454.357970133423805, 5140938.793855709955096 ] } },
{ "type": "Feature", "id": "mastaba", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753511.790332019329071, 5140925.659078708849847 ] } },
{ "type": "Feature", "id": "hall_8", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753405.112261723726988, 5140930.900920059531927 ] } },
{ "type": "Feature", "id": "birds", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753560.363374527543783, 5141014.868127717636526 ] } },
{ "type": "Feature", "id": "africa", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753560.159564102068543, 5140954.168612323701382 ] } },
{ "type": "Feature", "id": "animals", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753558.588560419157147, 5140907.84720469545573 ] } },
{ "type": "Feature", "id": "americas", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753400.755518516525626, 5141017.767494931817055 ] } },
{ "type": "Feature", "id": "hall_7", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753425.809495508670807, 5140948.384924443438649 ] } },
{ "type": "Feature", "id": "fm_store", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753419.202562866732478, 5140912.928526611067355 ] } },
{ "type": "Feature", "id": "nature_walk", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753559.940988035872579, 5140998.319427615962923 ] } },
{ "type": "Feature", "id": "maximo", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753483.036691509187222, 5141011.125253015197814 ] } },
{ "type": "Feature", "id": "mammals", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753559.959070103242993, 5140981.582581292837858 ] } },
{ "type": "Feature", "id": "hall_5", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753431.528731154277921, 5140982.831600999459624 ] } },
{ "type": "Feature", "id": "rice_gallery", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753600.179601952433586, 5140967.606361384503543 ] } },
{ "type": "Feature", "id": "pawnee_lodge", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753377.648188157007098, 5140948.91603210568428 ] } },
{ "type": "Feature", "id": "conn_thread", "properties": { }, "geometry": { "type": "Point", "coordinates": [ -9753530.748885693028569, 5140942.424736397340894 ] } }
]
}
