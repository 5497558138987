{
  "data": {
    "allPois": [
      {
        "id": "55717249",
        "shortName": "Harris Collection",
        "fullName": "Harris Learning Collection",
        "mapId": "harris",
        "websiteUrl": "https://harris.fieldmuseum.org/",
        "updatedAt": "2023-01-03T10:18:27-06:00",
        "sponsorAcknowledgment": "",
        "labelOverride": "",
        "labelAlignment": "",
        "description": "<p>The N. W. Harris Learning Collection at the Field Museum gives educators and parents a chance to take the Museum&#39;s collection to their classroom or home. Borrow these specimens, items, and related curricular materials for up to three weeks, and when you return your borrowed treasures, peruse the over 100-year-old Learning Collection to take something new.</p>\n",
        "colorCategory": "Amenity",
        "closed": "Open",
        "image": {
          "id": "22440703",
          "url": "https://www.datocms-assets.com/44232/1665071249-hlc_redfox-sittingadult_zm-004-3.jpg?w=500"
        }
      },
      {
        "id": "121516228",
        "shortName": "Regenstein Pacific Lab",
        "fullName": "Regenstein Pacific Lab",
        "mapId": "conservation_lab",
        "websiteUrl": "https://www.fieldmuseum.org/science/research/area/regenstein-pacific-lab",
        "updatedAt": "2022-05-06T19:30:14Z",
        "sponsorAcknowledgment": "",
        "labelOverride": "",
        "labelAlignment": "",
        "description": "",
        "colorCategory": "Culture",
        "closed": "Open",
        "image": null
      },
      {
        "id": "121515814",
        "shortName": "Mastaba",
        "fullName": "Mastaba",
        "mapId": "mastaba",
        "websiteUrl": "",
        "updatedAt": "2022-05-06T16:59:50Z",
        "sponsorAcknowledgment": "",
        "labelOverride": "",
        "labelAlignment": "",
        "description": "Mastaba, a type of ancient Egyptian tomb, that houses two authentic chamber rooms from the burial site of 5th Dynasty Egyptian Pharaoh Unis’s son Unis-Ankh.\n\n",
        "colorCategory": "Culture",
        "closed": "Open",
        "image": null
      },
      {
        "id": "Ze4FBvc_SgyTRH6gjBxbdA",
        "shortName": "Connecting Threads",
        "fullName": "Connecting Threads",
        "mapId": "conn_thread",
        "websiteUrl": "https://www.fieldmuseum.org/exhibition/connecting-threads-africa-fashion-in-chicago",
        "updatedAt": "2025-02-26T09:10:32-06:00",
        "sponsorAcknowledgment": "",
        "labelOverride": "",
        "labelAlignment": "",
        "description": "<div class=\"exhibit-flyer-page__header-description\">\n<p>Opening February 28, 2025</p>\n<p>In this exhibit, Chicago designers celebrate their heritage, culture, and communities by creating looks inspired by items in the Field Museum collections and the vibrant African design scene in Chicago.</p>\n</div>\n",
        "colorCategory": "Culture",
        "closed": "Open",
        "image": {
          "id": "MyBy24TAQD-26t3aZqHpRw",
          "url": "https://www.datocms-assets.com/44232/1730837907-connecting-threads-identity-image-7x5.jpg?w=500"
        }
      },
      {
        "id": "84774432",
        "shortName": "Family PlayLab",
        "fullName": "Crown Family PlayLab",
        "mapId": "playlab",
        "websiteUrl": "https://www.fieldmuseum.org/exhibitions/crown-family-playlab",
        "updatedAt": "2022-05-05T22:21:52Z",
        "sponsorAcknowledgment": "",
        "labelOverride": "",
        "labelAlignment": "",
        "description": "",
        "colorCategory": "Amenity",
        "closed": "Open",
        "image": {
          "id": "25213779",
          "url": "https://www.datocms-assets.com/44232/1632764187-17-069-2163-andersonwebexport.jpg"
        }
      },
      {
        "id": "84774436",
        "shortName": "Pawnee Lodge",
        "fullName": "Pawnee Earth Lodge",
        "mapId": "pawnee_lodge",
        "websiteUrl": "https://www.fieldmuseum.org/exhibitions/pawnee-earth-lodge",
        "updatedAt": "2022-05-05T21:51:06Z",
        "sponsorAcknowledgment": "",
        "labelOverride": "",
        "labelAlignment": "",
        "description": "",
        "colorCategory": "Culture",
        "closed": "Open",
        "image": {
          "id": "27667076",
          "url": "https://www.datocms-assets.com/44232/1634586796-17-069-3746-andersonwebexport.jpg"
        }
      },
      {
        "id": "84774443",
        "shortName": "Science Hub",
        "fullName": "Grainger Science Hub",
        "mapId": "sci_hub",
        "websiteUrl": "https://www.fieldmuseum.org/exhibitions/grainger-science-hub",
        "updatedAt": "2022-05-05T18:54:30Z",
        "sponsorAcknowledgment": "",
        "labelOverride": "",
        "labelAlignment": "",
        "description": "Science hub",
        "colorCategory": "Amenity",
        "closed": "Open",
        "image": {
          "id": "25215216",
          "url": "https://www.datocms-assets.com/44232/1632764832-17-069-3735-andersonwebexport.jpg"
        }
      },
      {
        "id": "84774442",
        "shortName": "Maori House",
        "fullName": "Maori Meeting House, Ruatepupuke II",
        "mapId": "maori_house",
        "websiteUrl": "https://www.fieldmuseum.org/exhibitions/maori-meeting-house-ruatepupuke-ii",
        "updatedAt": "2022-05-05T18:46:29Z",
        "sponsorAcknowledgment": "",
        "labelOverride": "",
        "labelAlignment": "",
        "description": "The Maori are the first settlers of New Zealand. Today, they have a strong presence in New Zealand’s government and society and maintain traditions that celebrate the past and flourish with contemporary inspiration. Built in 1881 on Tokomaru Bay, this wharenui (FAH-reh-new-EE) is one of only three such Maori meeting houses outside of New Zealand. The house is still used in the spirit of its original purpose, welcoming diverse groups of many backgrounds from around Chicago and the world. Structural elements of the house form the body of Ruatepupuke (roo-AH-tay-PU-pu-keh), the Maori ancestor who brought the art of woodcarving to the world. Look for his carved face at the top of the roof, as his arms welcome you inside. ",
        "colorCategory": "Culture",
        "closed": "Open",
        "image": {
          "id": "25212988",
          "url": "https://www.datocms-assets.com/44232/1632763834-17-069-3791-andersonwebexport.jpg"
        }
      },
      {
        "id": "120821301",
        "shortName": "Native Truths",
        "fullName": "Native Truths: Our Voices, Our Stories",
        "mapId": "hall_8",
        "websiteUrl": "https://www.fieldmuseum.org/exhibitions/native-truths-our-voices-our-stories",
        "updatedAt": "2022-04-05T02:42:03Z",
        "sponsorAcknowledgment": "",
        "labelOverride": "",
        "labelAlignment": "",
        "description": "Experience stories told by Native people of self-determination, resilience, continuity, and the future.\n\n",
        "colorCategory": "Culture",
        "closed": "Open",
        "image": {
          "id": "46242004",
          "url": "https://www.datocms-assets.com/44232/1649126451-field-museum-7-flutes-webexport.png"
        }
      },
      {
        "id": "112942845",
        "shortName": "Jurassic Oceans",
        "fullName": "Jurassic Oceans: Monsters of the Deep",
        "mapId": "hall_7",
        "websiteUrl": "https://www.fieldmuseum.org/exhibitions/jurassic-oceans-monsters-deep",
        "updatedAt": "2022-03-01T17:14:20Z",
        "sponsorAcknowledgment": "*Jurassic Oceans: Monsters of the Deep* was curated and produced by the Natural History Museum, London.",
        "labelOverride": "",
        "labelAlignment": "",
        "description": "Dive into the prehistoric seas that were home to enormous marine reptiles, crocodiles, and sharks.\n\nIn *Jurassic Oceans: Monsters of the Deep*, meet the underwater giants that lived 200 million years ago. As dinosaurs reached new heights, sea creatures became larger, faster, and fiercer. Experience what it’s like to stand (or swim) next to top predators like a plesiosaur—a reptile with flippers, a very long neck, and super sharp teeth.\n\n*The exhibition is presented in both English and Spanish. La exhibición está presentada en inglés y español.*\n\n",
        "colorCategory": "Dinosaurs",
        "closed": "Open",
        "image": {
          "id": "43558186",
          "url": "https://www.datocms-assets.com/44232/1646154810-bait_ball_cnicholls2009.jpg"
        }
      },
      {
        "id": "84774479",
        "shortName": "Biomechanics",
        "fullName": " The Machine Inside: Biomechanics ",
        "mapId": "biomechanics",
        "websiteUrl": "https://www.fieldmuseum.org/exhibitions/machine-inside-biomechanics",
        "updatedAt": "2022-03-01T17:10:31Z",
        "sponsorAcknowledgment": "",
        "labelOverride": "",
        "labelAlignment": "center (default)",
        "description": "Investigate nature’s mechanical marvels: jaws that can crush over 8,000 pounds in one bite, ears that act as air conditioners, and a spring-like body that sprints at 80 miles per hour. In *The Machine Inside: Biomechanics*, all ages can try out interactive experiences that show how mechanics like wings and fins, pumps and pipes, and claws and jaws work in nature. Then, learn how to spot biomimicry, or examples of how humans use nature’s designs to solve problems. ",
        "colorCategory": "Nature",
        "closed": "Open",
        "image": {
          "id": "34608414",
          "url": "https://www.datocms-assets.com/44232/1639086458-biomechanicsgn91950064d.jpg"
        }
      },
      {
        "id": "84774454",
        "shortName": "Plants",
        "fullName": "Plants of the World",
        "mapId": "plants",
        "websiteUrl": "https://www.fieldmuseum.org/exhibitions/plants-world",
        "updatedAt": "2022-01-07T16:50:40Z",
        "sponsorAcknowledgment": "",
        "labelOverride": "",
        "labelAlignment": "center (default)",
        "description": "Peer through the glass of the grand wooden display cases to see meticulous, lifelike recreations of plant specimens. A stroll through Plants of the World is an introduction to the varied uses of plants, from medicines and rubber to chocolates and teas. The hall opened in 1983 with 300 plants—representing nearly a third of all plant families. Wander this exhibition for a glimpse of the world of botany, from exotic specimens to plants you might spot in your own backyard.",
        "colorCategory": "Nature",
        "closed": "Open",
        "image": {
          "id": "25212384",
          "url": "https://www.datocms-assets.com/44232/1632763552-17-069-1530-andersonwebexport.jpg"
        }
      },
      {
        "id": "84774441",
        "shortName": "Birds",
        "fullName": "Ronald and Christina Gidwitz Hall of Birds",
        "mapId": "birds",
        "websiteUrl": "https://www.fieldmuseum.org/exhibitions/ronald-and-christina-gidwitz-hall-birds",
        "updatedAt": "2022-01-07T16:49:22Z",
        "sponsorAcknowledgment": "",
        "labelOverride": "",
        "labelAlignment": "",
        "description": "Peek through this window into our collective ornithological obsession—more than 1,000 birds await you. With rail displays, you can explore new science alongside traditional taxidermy. And you can feel immersed in the natural environments of birds through wall projections and environmental sound.\n\nWe started building our collection as part of the World’s Columbian Exposition of 1893—and we never stopped collecting. Today, the Field Museum’s scientific bird collection has more than 530,000 specimens. It is the third largest in the United States and represents 90 percent of the world’s known bird species.",
        "colorCategory": "Nature",
        "closed": "Open",
        "image": {
          "id": "25212301",
          "url": "https://www.datocms-assets.com/44232/1632763512-17-069-5324-andersonwebexport.jpg"
        }
      },
      {
        "id": "84774439",
        "shortName": "Meteorites",
        "fullName": "Meteorites",
        "mapId": "meteorites",
        "websiteUrl": "https://www.fieldmuseum.org/exhibitions/meteorites",
        "updatedAt": "2022-01-07T16:48:20Z",
        "sponsorAcknowledgment": "",
        "labelOverride": "",
        "labelAlignment": "",
        "description": "",
        "colorCategory": "Nature",
        "closed": "Open",
        "image": {
          "id": "25213411",
          "url": "https://www.datocms-assets.com/44232/1632764010-meteoritesimg0759-knightdropboxwebexport0.jpg"
        }
      },
      {
        "id": "84774435",
        "shortName": "Gems",
        "fullName": "Grainger Hall of Gems",
        "mapId": "gems",
        "websiteUrl": "https://www.fieldmuseum.org/exhibitions/grainger-hall-gems",
        "updatedAt": "2022-01-07T16:46:51Z",
        "sponsorAcknowledgment": "",
        "labelOverride": "",
        "labelAlignment": "right",
        "description": "See a stunning display of hundreds of precious stones and eye-popping jewelry. Each display features a gem in its three stages of transformation: raw crystal, cut and polished stone, and mounted jewel.\n\nThe *Grainger Hall of Gems* has been a visitor favorite since the museum opened in 1921. And it has a history older than the Field Museum itself. Tiffany & Co. displayed a gem collection at the 1893 World’s Columbian Exposition. It was then purchased and donated to Chicago’s then-new natural history museum. Since then, our collection has grown to include more than 600 gemstones and 150 pieces of antique and contemporary jewelry.",
        "colorCategory": "Nature",
        "closed": "Open",
        "image": {
          "id": "25214373",
          "url": "https://www.datocms-assets.com/44232/1632764451-17-069-1189-andersonwebexport.jpg"
        }
      },
      {
        "id": "84774456",
        "shortName": "Ancient Americas",
        "fullName": "Robert R. McCormick Halls of the Ancient Americas",
        "mapId": "americas",
        "websiteUrl": "https://www.fieldmuseum.org/exhibitions/robert-r-mccormick-halls-ancient-americas",
        "updatedAt": "2022-01-07T16:45:53Z",
        "sponsorAcknowledgment": "",
        "labelOverride": "",
        "labelAlignment": "",
        "description": "See how ancient cultures intertwine with our own. This exhibition journeys through 13,000 years of human ingenuity and achievement in the Americas. Societies thrived here long before the arrival of Europeans. Follow the path from hunters and gatherers to the Aztec and Inca empires, as well as, from the arrival of European settlers to the present day. With interactive displays and thousands of artifacts, explore what we’ve learned recently about these advanced early civilizations and what they teach us about our way of life today.",
        "colorCategory": "Culture",
        "closed": "Open",
        "image": {
          "id": "25214721",
          "url": "https://www.datocms-assets.com/44232/1632764605-17-069-0448-andersonwebexport.jpg"
        }
      },
      {
        "id": "84774450",
        "shortName": "What is an Animal?",
        "fullName": "What is an Animal? and Animal Biology ",
        "mapId": "animals",
        "websiteUrl": "https://www.fieldmuseum.org/exhibitions/what-animal-animal-biology",
        "updatedAt": "2022-01-07T16:44:24Z",
        "sponsorAcknowledgment": "",
        "labelOverride": "What is\nan Animal?",
        "labelAlignment": "",
        "description": "Pass under the shadows of giant sea creatures and explore the colors, shapes, and sounds that make up the animal kingdom. In *What is an Animal?* you can meet mollusks hiding two skeletons and a frog that looks like a leaf. Discover the animal world and learn what makes each creature—including you!—an animal. Then, investigate how animals are related in *Animal Biology*. Through specimens, models, and games, this journey through the animal kingdom showcases the vibrant diversity of life on Earth.",
        "colorCategory": "Nature",
        "closed": "Open",
        "image": {
          "id": "25213839",
          "url": "https://www.datocms-assets.com/44232/1632764214-17-069-5583-andersonwebexport.jpg"
        }
      },
      {
        "id": "84774455",
        "shortName": "Underground Adventure",
        "fullName": "Underground Adventure",
        "mapId": "underground",
        "websiteUrl": "https://www.fieldmuseum.org/exhibitions/underground-adventure",
        "updatedAt": "2022-01-07T16:34:26Z",
        "sponsorAcknowledgment": "",
        "labelOverride": "Underground\nAdventure",
        "labelAlignment": "",
        "description": "Step into our shrinking machine and come out smaller than a penny. (Don't worry, we'll change you back to your original size!) And explore the wild and exciting world of underground insects. Then meet a creepy, crawly cast of characters, including a giant mole cricket and a wolf spider.\n\nYou can learn how some creatures live in the soil and how every species needs soil to survive and thrive. Soil nourishes plants like cotton, which we use to make the clothes we wear. And it sustains our food sources, from fruits and vegetables to animal proteins.\n\nAfter your adventure, you can tell your friends why we need healthy soil and why the next generation does too!",
        "colorCategory": "Nature",
        "closed": "Open",
        "image": {
          "id": "25214989",
          "url": "https://www.datocms-assets.com/44232/1632764727-17-069-7390-andersonwebexport.jpg"
        }
      },
      {
        "id": "84774451",
        "shortName": "Máximo the Titanosaur",
        "fullName": "Máximo the Titanosaur",
        "mapId": "maximo",
        "websiteUrl": "https://www.fieldmuseum.org/exhibitions/maximo-titanosaur",
        "updatedAt": "2022-01-07T16:32:45Z",
        "sponsorAcknowledgment": "",
        "labelOverride": "Máximo\nthe\nTitanosaur",
        "labelAlignment": "",
        "description": "Come face-to-face with the largest dinosaur ever discovered—the titanosaur *Patagotitan mayorum*. Named Máximo, meaning “maximum” or “most” in Spanish. This long-necked, plant-eating dinosaur lived over 100 million years ago in what is now Patagonia, Argentina.\n\nOur titanosaur cast reaches 122 feet across and stands 28 feet tall at the head. Modeled from fossil bones, this cast conveys the sheer size of the biggest animal ever to live. \n\n*The exhibition is presented in both English and Spanish. La exhibición está presentada en inglés y español.*\n\nAnd you can get to know Máximo better—by texting him at (844) 994-3466 (English only). You can ask our friendly titanosaur what life was like during the Cretaceous Period and more.",
        "colorCategory": "Dinosaurs",
        "closed": "Open",
        "image": {
          "id": "25213173",
          "url": "https://www.datocms-assets.com/44232/1632763919-fieldmuseummaximo-451-hewettwebexport.jpg"
        }
      },
      {
        "id": "84774444",
        "shortName": "Evolving Planet",
        "fullName": "Griffin Halls of Evolving Planet & SUE the T. rex",
        "mapId": "evolving_planet",
        "websiteUrl": "https://www.fieldmuseum.org/exhibitions/evolving-planet",
        "updatedAt": "2022-01-07T16:29:45Z",
        "sponsorAcknowledgment": "The *Griffin Halls of Evolving Planet* are part of the Griffin Dinosaur Experience, made possible by the generous support of the Kenneth C. Griffin Charitable Fund.",
        "labelOverride": "",
        "labelAlignment": "center (default)",
        "description": "Discover the many creatures that have roamed the earth throughout history, from single-celled organisms to our extended human family. You can see a variety of real fossils and experience the story of evolution, the process that connects all living things on Earth.\n\nDon’t miss the *Elizabeth Morse Genius Hall of Dinosaurs* to get up close and personal with more than a dozen of the biggest creatures in our history. Then, experience the world of SUE the *T. rex*, our famous fossil with a big personality.",
        "colorCategory": "Dinosaurs",
        "closed": "Open",
        "image": {
          "id": "25213656",
          "url": "https://www.datocms-assets.com/44232/1632764129-field2suevisitors1266-hewettwebexport.jpg"
        }
      },
      {
        "id": "84774434",
        "shortName": "Nature and Wilderness",
        "fullName": "Nature Walk and Messages from the Wilderness",
        "mapId": "nature_walk",
        "websiteUrl": "https://www.fieldmuseum.org/exhibitions/nature-walk-messages-wilderness",
        "updatedAt": "2022-01-07T16:28:29Z",
        "sponsorAcknowledgment": "",
        "labelOverride": "",
        "labelAlignment": "",
        "description": "Follow the *Nature Walk* boardwalk to discover animal habitats from right here in the Midwest and across the Americas. At every turn, encounter a diorama scene of plant and animal life—and see if you can spot each critter, down to the smallest beetle! The hand-crafted landscapes and diverse animal groups make *Nature Walk* an immersive habitat tour for all ages.\n\nThen visit national parks from across the Americas. Dioramas in *Messages from the Wilderness* show species—from the common to the extinct—as they would appear in their natural habitats. Tips and audio recordings from park rangers reveal the importance of protecting the places where animals live. ",
        "colorCategory": "Nature",
        "closed": "Open",
        "image": {
          "id": "25214278",
          "url": "https://www.datocms-assets.com/44232/1632764410-17-069-6379-andersonwebexport.jpg"
        }
      },
      {
        "id": "84774437",
        "shortName": "Fossil Lab",
        "fullName": "McDonald’s Fossil Preparation Lab",
        "mapId": "fossil_lab",
        "websiteUrl": "https://www.fieldmuseum.org/exhibitions/mcdonalds-fossil-preparation-lab",
        "updatedAt": "2022-01-07T16:24:56Z",
        "sponsorAcknowledgment": "",
        "labelOverride": "",
        "labelAlignment": "",
        "description": "Watch as stone slabs become prehistoric scientific specimens. After scientists discover fossils out in the field, they carefully package them at the site for their journey to the museum. Then, a team of preparators and volunteers begin extracting the fossils from their stone tombs—a job that can take months or years. Preparing the fossils of SUE the *T. rex* took 12 person-years!\n\nSee the steps in the delicate process to ready fossilized specimens for display. Can you spot miniature versions of tools like jackhammers, circular saws, and sandblasters? The mini sandblasters shoot baking soda—strong enough to whittle away rock but gentle enough to protect precious fossils.",
        "colorCategory": "Amenity",
        "closed": "Open",
        "image": {
          "id": "25213337",
          "url": "https://www.datocms-assets.com/44232/1632763979-17-069-4332-andersonwebexport.jpg"
        }
      }
    ]
  }
}
