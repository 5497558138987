{
"type": "FeatureCollection",
"name": "main_level_areas",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::3857" } },
"features": [
{ "type": "Feature", "id": "mastaba", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753514.858574939891696, 5140930.035147480666637 ], [ -9753514.711798729375005, 5140921.183687690645456 ], [ -9753508.722089095041156, 5140921.28300993796438 ], [ -9753508.868865305557847, 5140930.134469727985561 ], [ -9753514.858574939891696, 5140930.035147480666637 ] ] ] ] } },
{ "type": "Feature", "id": "sci_hub", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753461.184043746441603, 5140944.971589189954102 ], [ -9753460.977674186229706, 5140932.52630362007767 ], [ -9753448.931702591478825, 5140932.726051697507501 ], [ -9753449.138072151690722, 5140945.171337267383933 ], [ -9753461.184043746441603, 5140944.971589189954102 ] ] ] ] } },
{ "type": "Feature", "id": "africa", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753525.481762673705816, 5140971.286167440935969 ], [ -9753525.479755118489265, 5140936.830465355888009 ], [ -9753605.456204000860453, 5140936.202481406740844 ], [ -9753605.61374250613153, 5140954.770470722578466 ], [ -9753594.523026717826724, 5140954.857647305354476 ], [ -9753594.64774052798748, 5140970.743159930221736 ], [ -9753525.481762673705816, 5140971.286167440935969 ] ] ] ] } },
{ "type": "Feature", "id": "", "properties": { "closed": 0, "label": "Bird Habitats" }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753565.572200978174806, 5140936.512132876552641 ], [ -9753565.274356497451663, 5140920.389487648382783 ], [ -9753604.959582084789872, 5140919.656250030733645 ], [ -9753605.26764159090817, 5140935.778706549666822 ], [ -9753565.572200978174806, 5140936.512132876552641 ] ] ] ] } },
{ "type": "Feature", "id": "animals", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753604.959582084789872, 5140919.656250030733645 ], [ -9753524.966332426294684, 5140921.13412491697818 ], [ -9753524.627231042832136, 5140900.636006940156221 ], [ -9753604.603639326989651, 5140899.150786129757762 ], [ -9753604.959582084789872, 5140919.656250030733645 ] ] ] ] } },
{ "type": "Feature", "id": "mammals", "properties": { "closed": 0, "label": "" }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753604.515620952472091, 5140894.471893980167806 ], [ -9753624.759478114545345, 5140894.120713461190462 ], [ -9753627.263055292889476, 5141029.31671367585659 ], [ -9753607.058847540989518, 5141029.66506208665669 ], [ -9753604.515620952472091, 5140894.471893980167806 ] ] ], [ [ [ -9753606.274463273584843, 5140988.471249494701624 ], [ -9753539.710568096488714, 5140989.705241876654327 ], [ -9753525.799378724768758, 5140989.968110671266913 ], [ -9753525.670325826853514, 5140971.709801921620965 ], [ -9753605.925863210111856, 5140970.227081617340446 ], [ -9753606.274463273584843, 5140988.471249494701624 ] ] ] ] } },
{ "type": "Feature", "id": "rice_gallery", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753594.826635459437966, 5140970.432230850681663 ], [ -9753594.533212710171938, 5140954.548938748426735 ], [ -9753605.622377084568143, 5140954.343975422903895 ], [ -9753605.925863210111856, 5140970.227081617340446 ], [ -9753594.826635459437966, 5140970.432230850681663 ] ] ] ] } },
{ "type": "Feature", "id": "birds", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753527.201100433245301, 5141027.451786724850535 ], [ -9753526.807377506047487, 5141006.139137038961053 ], [ -9753606.585495740175247, 5141004.749297419562936 ], [ -9753606.976939864456654, 5141025.977928204461932 ], [ -9753527.201100433245301, 5141027.451786724850535 ] ] ] ] } },
{ "type": "Feature", "id": "nature_walk", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753518.789369285106659, 5140990.142869132570922 ], [ -9753606.274463273584843, 5140988.471249494701624 ], [ -9753606.585495740175247, 5141004.749297419562936 ], [ -9753519.100401746109128, 5141006.420917057432234 ], [ -9753519.144361209124327, 5141008.721558735705912 ], [ -9753507.739608235657215, 5141008.939474815502763 ], [ -9753507.330137006938457, 5140987.509582086466253 ], [ -9753518.734889980405569, 5140987.291666006669402 ], [ -9753518.789369285106659, 5140990.142869132570922 ] ] ] ] } },
{ "type": "Feature", "id": "fm_store", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753396.53148865327239, 5140923.725139087066054 ], [ -9753396.191173151135445, 5140902.87763658259064 ], [ -9753441.873637080192566, 5140902.131914134137332 ], [ -9753442.213952582329512, 5140922.979416638612747 ], [ -9753396.53148865327239, 5140923.725139087066054 ] ] ] ] } },
{ "type": "Feature", "id": "founders", "properties": { "closed": 1, "label": "Founders Room" }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753445.578118955716491, 5140902.073252767324448 ], [ -9753445.283580465242267, 5140883.290759662166238 ], [ -9753449.225864904001355, 5140883.154818823561072 ], [ -9753456.610879745334387, 5140883.154774622060359 ], [ -9753459.914564806967974, 5140883.087891280651093 ], [ -9753460.292563183233142, 5140901.759021651931107 ], [ -9753445.578118955716491, 5140902.073252767324448 ] ] ] ] } },
{ "type": "Feature", "id": "pawnee_lodge", "properties": { "closed": 0, "label": "" }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753362.754906637594104, 5140940.078014170750976 ], [ -9753392.2468900103122, 5140939.591681121848524 ], [ -9753392.54331536591053, 5140957.750502436421812 ], [ -9753363.051446784287691, 5140958.243867472745478 ], [ -9753362.754906637594104, 5140940.078014170750976 ] ] ] ] } },
{ "type": "Feature", "id": "field_bistro", "properties": { "closed": 0, "label": "Field Bistro" }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753449.413015797734261, 5141000.869047611020505 ], [ -9753462.787529891356826, 5141000.651824035681784 ], [ -9753463.215160027146339, 5141026.981126775033772 ], [ -9753459.21513875387609, 5141027.046093540266156 ], [ -9753459.404078057035804, 5141038.568583274260163 ], [ -9753469.355711735785007, 5141038.374720280058682 ], [ -9753469.614195728674531, 5141046.840071007609367 ], [ -9753462.978697754442692, 5141047.01339506264776 ], [ -9753459.602682564407587, 5141047.076818367466331 ], [ -9753452.261877061799169, 5141047.212759209796786 ], [ -9753448.410219844430685, 5141047.25807283166796 ], [ -9753447.97974050976336, 5141028.99668628256768 ], [ -9753449.840645933523774, 5141027.198350350372493 ], [ -9753449.413015797734261, 5141000.869047611020505 ] ] ] ] } },
{ "type": "Feature", "id": "field_bistro_rooms", "properties": { "closed": 1, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753433.499503914266825, 5141029.234067210927606 ], [ -9753433.165872102603316, 5141008.882526771165431 ], [ -9753443.438395854085684, 5141008.714124741964042 ], [ -9753443.772027665749192, 5141029.065665181726217 ], [ -9753433.499503914266825, 5141029.234067210927606 ] ] ] ] } },
{ "type": "Feature", "id": "field_bistro_stairs", "properties": { "closed": 2, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753447.97974050976336, 5141028.99668628256768 ], [ -9753443.772027665749192, 5141029.065665181726217 ], [ -9753443.438395854085684, 5141008.714124741964042 ], [ -9753449.539005365222692, 5141008.626259212382138 ], [ -9753449.840645933523774, 5141027.198350350372493 ], [ -9753447.97974050976336, 5141028.99668628256768 ] ] ] ] } },
{ "type": "Feature", "id": "pawnee_lodge_behind1", "properties": { "closed": 2, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753363.323655961081386, 5140974.919221705757082 ], [ -9753363.051446784287691, 5140958.243867472745478 ], [ -9753385.714674208313227, 5140957.86197345983237 ], [ -9753385.986883385106921, 5140974.537327692843974 ], [ -9753363.323655961081386, 5140974.919221705757082 ] ] ] ] } },
{ "type": "Feature", "id": "hall_7", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753392.2468900103122, 5140939.591681121848524 ], [ -9753434.541683692485094, 5140938.894225285388529 ], [ -9753442.729595974087715, 5140938.760565463453531 ], [ -9753443.026136120781302, 5140965.281235251575708 ], [ -9753385.853386871516705, 5140966.359419478103518 ], [ -9753385.714674208313227, 5140957.86197345983237 ], [ -9753392.54331536591053, 5140957.750502436421812 ], [ -9753392.2468900103122, 5140939.591681121848524 ] ] ] ] } },
{ "type": "Feature", "id": "museum_store_behind", "properties": { "closed": 2, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753368.157615503296256, 5140924.188315376639366 ], [ -9753362.675038000568748, 5140924.277813200838864 ], [ -9753362.633718824014068, 5140917.67948644887656 ], [ -9753367.995785279199481, 5140917.545662513934076 ], [ -9753367.813858773559332, 5140903.511129395104945 ], [ -9753396.195996643975377, 5140903.113197189755738 ], [ -9753396.53246658295393, 5140923.725123123265803 ], [ -9753368.157615503296256, 5140924.188315376639366 ] ] ] ] } },
{ "type": "Feature", "id": "kitchen_catering", "properties": { "closed": 2, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753520.005730371922255, 5140881.840724009089172 ], [ -9753520.474368909373879, 5140902.797743668779731 ], [ -9753506.010526068508625, 5140903.121182509697974 ], [ -9753505.541887531057, 5140882.164162850007415 ], [ -9753508.794099438935518, 5140882.098321585915983 ], [ -9753516.444335738196969, 5140881.947530623525381 ], [ -9753520.005730371922255, 5140881.840724009089172 ] ] ] ] } },
{ "type": "Feature", "id": "server_room", "properties": { "closed": 2, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753565.572199994698167, 5140936.512079652398825 ], [ -9753519.347201529890299, 5140937.366078414954245 ], [ -9753519.049357049167156, 5140921.243433186784387 ], [ -9753565.274356497451663, 5140920.389487648382783 ], [ -9753565.572199994698167, 5140936.512079652398825 ] ] ] ] } },
{ "type": "Feature", "id": "northwest_stairs", "properties": { "closed": 2, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753522.985100500285625, 5141045.864679183810949 ], [ -9753519.337354550138116, 5141046.023276833817363 ], [ -9753512.064519461244345, 5141046.0912472512573 ], [ -9753508.502986734732985, 5141046.158155770041049 ], [ -9753508.605609586462379, 5141054.202960781753063 ], [ -9753500.729767283424735, 5141054.257276935502887 ], [ -9753500.69565836712718, 5141037.372917204163969 ], [ -9753511.748528072610497, 5141037.271514729596674 ], [ -9753511.650369539856911, 5141025.684425763785839 ], [ -9753522.64686400257051, 5141025.591237537562847 ], [ -9753522.985100500285625, 5141045.864679183810949 ] ] ] ] } },
{ "type": "Feature", "id": "coat_check", "properties": { "closed": 1, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753447.983469244092703, 5140927.816000684164464 ], [ -9753447.851483073085546, 5140919.730615139938891 ], [ -9753467.841340588405728, 5140919.404299871996045 ], [ -9753467.973326759412885, 5140927.489685416221619 ], [ -9753447.983469244092703, 5140927.816000684164464 ] ] ] ] } },
{ "type": "Feature", "id": "", "properties": { "closed": 1, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753525.670325826853514, 5140971.709801921620965 ], [ -9753525.722984420135617, 5140979.159900818951428 ], [ -9753512.258045095950365, 5140979.404434448108077 ], [ -9753512.120521198958158, 5140971.960116586647928 ], [ -9753525.670325826853514, 5140971.709801921620965 ] ] ] ] } },
{ "type": "Feature", "id": "hall_5", "properties": { "closed": 0, "label": "" }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753443.026136120781302, 5140965.281235251575708 ], [ -9753385.853386871516705, 5140966.359419478103518 ], [ -9753385.986883385106921, 5140974.537327692843974 ], [ -9753386.278030088171363, 5140992.52321709971875 ], [ -9753443.371153401210904, 5140991.587663408368826 ], [ -9753443.026136120781302, 5140965.281235251575708 ] ] ] ] } },
{ "type": "Feature", "id": "", "properties": { "closed": 1, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753443.371153401210904, 5140991.587663408368826 ], [ -9753449.260732198134065, 5140991.492905299179256 ], [ -9753449.141648842021823, 5140984.091419409960508 ], [ -9753443.252070045098662, 5140984.186177519150078 ], [ -9753443.371153401210904, 5140991.587663408368826 ] ] ] ] } },
{ "type": "Feature", "id": "", "properties": { "closed": 1, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753443.12977253831923, 5140973.183169770985842 ], [ -9753443.228841383010149, 5140980.736842043697834 ], [ -9753448.874014250934124, 5140980.662803783081472 ], [ -9753448.774945406243205, 5140973.10913151036948 ], [ -9753443.12977253831923, 5140973.183169770985842 ] ] ] ] } },
{ "type": "Feature", "id": "pawnee_lodge_behind2", "properties": { "closed": 1, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753385.986883385106921, 5140974.537327692843974 ], [ -9753363.323655961081386, 5140974.919221705757082 ], [ -9753363.626581868156791, 5140992.896143239922822 ], [ -9753386.289809292182326, 5140992.514249227009714 ], [ -9753385.986883385106921, 5140974.537327692843974 ] ] ] ] } },
{ "type": "Feature", "id": "pawnee_lodge", "properties": { "closed": 0, "label": "Pawnee Lodge" }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753392.2468900103122, 5140939.591681121848524 ], [ -9753362.754906637594104, 5140940.078014170750976 ], [ -9753392.246775219216943, 5140939.584649134427309 ], [ -9753392.2468900103122, 5140939.591681121848524 ] ] ] ] } },
{ "type": "Feature", "id": "hall_7", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753434.541683692485094, 5140938.894225285388529 ], [ -9753392.2468900103122, 5140939.591681121848524 ], [ -9753392.246775219216943, 5140939.584649134427309 ], [ -9753434.541683692485094, 5140938.894225285388529 ] ] ] ] } },
{ "type": "Feature", "id": "hall_8", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753362.675038000568748, 5140924.277813200838864 ], [ -9753434.284022221341729, 5140923.108865153044462 ], [ -9753434.541703093796968, 5140938.894224965013564 ], [ -9753362.754906637594104, 5140940.078014170750976 ], [ -9753362.675038000568748, 5140924.277813200838864 ] ] ] ] } },
{ "type": "Feature", "id": "americas", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753362.675038000568748, 5140924.277813200838864 ], [ -9753362.754906637594104, 5140940.078014170750976 ], [ -9753363.61711454205215, 5140992.896300278604031 ], [ -9753449.260732198134065, 5140991.492905299179256 ], [ -9753449.539005365222692, 5141008.626259212382138 ], [ -9753433.166030395776033, 5141008.892182605341077 ], [ -9753433.499503914266825, 5141029.234067210927606 ], [ -9753363.973622489720583, 5141030.333437904715538 ], [ -9753364.08158372156322, 5141034.230408727191389 ], [ -9753343.803741354495287, 5141034.615574449300766 ], [ -9753341.311492560431361, 5140899.411077931523323 ], [ -9753361.521346857771277, 5140899.069936143234372 ], [ -9753361.622032342478633, 5140903.579930437728763 ], [ -9753367.813858773559332, 5140903.511129395104945 ], [ -9753367.995785279199481, 5140917.545662513934076 ], [ -9753362.633718824014068, 5140917.67948644887656 ], [ -9753362.675038000568748, 5140924.277813200838864 ] ] ] ] } },
{ "type": "Feature", "id": "conn_thread", "properties": { "closed": 0, "label": null }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ -9753524.620691552758217, 5140941.471910828724504 ], [ -9753524.77225916646421, 5140948.814341921359301 ], [ -9753512.971471734344959, 5140949.057942055165768 ], [ -9753512.819904120638967, 5140941.715510962530971 ], [ -9753524.620691552758217, 5140941.471910828724504 ] ] ] ] } }
]
}
